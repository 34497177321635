<template>
    <div class="edit_container">
        <quill-editor 
            v-model="content" 
            ref="myQuillEditor" 
            :options="editorOption" 
            @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
            @change="onEditorChange($event)">
        </quill-editor>
    </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
    components: {
        quillEditor
    },
    props: ["info"],
    data() {
        return {
            content: null,
            str: '',
            editorOption: {
                placeholder: "请输入",
                modules:{
                    // toolbar: false
                    toolbar:[
                        ['bold'],    //加粗，斜体，下划线，删除线
                        // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
                        // ['clean'],    //清除字体样式
                    ]
                }
                }
        }
    },
    methods: {
        onEditorReady(editor) { // 准备编辑器
            
        },
        onEditorBlur(){}, // 失去焦点事件
        onEditorFocus(){}, // 获得焦点事件
        onEditorChange(){
            this.$emit('Editor-change', this.content);
        }, // 内容改变事件
    },
    computed: {
        editor() {
            return this.$refs.myQuillEditor.quill;
        },
    },
    watch: {
        info(newValue, oldValue) {
            this.content = newValue;
        }
    },
    mounted() {
        this.content = this.info;  // 请求后台返回的内容字符串   
    }
}
</script>

<style>
    .ql-toolbar {
        text-align: left;
    }
</style>
